"use client";

/**
 * Third-party libraries.
 */
import { Call } from "@twilio/voice-sdk";
import { useEffect, useMemo, useState } from "react";

/**
 * Project components.
 */
import { ButtonCall, ButtonCallType } from "@/components/client/button";
import { CommunicationDirection, CommunicationLogStatus, useCommunicationLogContext } from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { PhoneIcon } from "@/components/client/svgs/icons";
import { useTwilioContext } from "@/components/client/twilio";
import { TimeUtility } from "@/components/common/time";
import { PlayWrightTestId } from "@/tests/constants";
import Icon, { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";

/**
 * Call control card properties.
 */
export type CallControlLiveCard = {
  data: CommunicationLog | null;
  /**
   * Callbacks for the call control card.
   */
  /**
   * Indicates that the call control card is visible.
   */
  visible?: boolean;
};

/**
 * Active call control card.
 * Includes a timer, client phone number, and call control buttons (mute, end call).
 */
export function CallControlLiveCard({
  data,
  visible = false
}: CallControlLiveCard) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    isMuted,
    toggleMute,
    getCall
  } = useTwilioContext();
  const {
    hangUp: hangUpCall,
    isHangingUp
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Active Twilio call associated with this communication log card.
   *
   * This could be null if there is no active call.
   *
   * Active call means the call is connected to the Twilio server.
   */
  const [call, setCall] = useState<Call | null>(null);

  /**
   * Duration of the call in milliseconds
   */
  const [duration, setDuration] = useState(0);
  const hangingUp = useMemo(() => data?.id ? isHangingUp({
    callId: data.id
  }) : false, [data?.id, isHangingUp]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useEffect(() => {
    if (!data?.dateStarted) {
      return;
    }
    const interval = setInterval(() => {
      setDuration(() => new Date().getTime() - data.dateStarted!.getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, [data?.dateStarted]);

  /**
   * Links the Twilio call to this card if applicable.
   * This enables the hangup and mute buttons to work.
   */
  useEffect(() => {
    if (!data?.id) {
      setCall(null);
      return;
    }
    setCall(getCall({
      callId: data.id
    }));
  }, [data?.id, getCall]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!data || !visible) {
    return null;
  }
  return <div className="flex h-[276px] animate-slide-left flex-col items-center justify-center gap-4 self-stretch bg-white p-[30px]" data-sentry-component="CallControlLiveCard" data-sentry-source-file="call-control-live-card.tsx">
      <div className="flex flex-col items-center justify-center gap-2 self-stretch">
        <div className="text-sm font-semibold text-tpl-navy">
          Live {TimeUtility.formatDuration({
          duration
        })}
        </div>
        {data.clientName && <div className="flex flex-col items-start self-stretch overflow-ellipsis">
            <div className="self-stretch text-center text-sm font-semibold text-tpl-navy">
              {data.clientName}
            </div>
          </div>}
        <div className="self-stretch text-center text-sm text-gray-400">
          {data?.direction === CommunicationDirection.INBOUND ? data.from : data.to}
        </div>
      </div>
      <div className="inline-flex items-start gap-2">
        <ButtonCall data-testid={PlayWrightTestId.CallControlLiveCard.MUTE_BUTTON} disabled={hangingUp || !call} icon={isMuted({
        callId: data.id
      }) ? <AudioMutedOutlined className="!text-tpl-navy" /> : <AudioOutlined className="!text-tpl-navy" />} onClick={event => {
        event.stopPropagation();
        toggleMute({
          callId: data.id
        });
      }} type={ButtonCallType.MUTE} data-sentry-element="ButtonCall" data-sentry-source-file="call-control-live-card.tsx" />
        <ButtonCall data-testid={PlayWrightTestId.CallControlLiveCard.END_BUTTON} disabled={hangingUp || !call} icon={<Icon className="!text-white" component={PhoneIcon} rotate={135} />} loading={hangingUp} onClick={() => {
        if (data?.status === CommunicationLogStatus.ONGOING) {
          hangUpCall({
            callId: data.id,
            direction: data.call.direction,
            status: data.call.status
          });
        }
      }} type={ButtonCallType.HANG_UP} data-sentry-element="ButtonCall" data-sentry-source-file="call-control-live-card.tsx" />
      </div>
    </div>;
}