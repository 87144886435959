"use client";

/**
 * Third-party libraries.
 */
// import { FilterOutlined as FilterOutlinedIcon } from "@ant-design/icons";
import { Alert, Button, Divider, Input, Tabs, TabsProps } from "antd";
import { useRouter, useSearchParams } from "next/navigation";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { CallCompleteCard, CallControlLiveCard, CallControlRingingCard, CallInformationCard, CallRoutingCard, CallSummaryCard } from "@/components/client/call";
import { CallPastInteractionsCard } from "@/components/client/call/call-past-interactions-card";
import { CallRecordingPlayerCard } from "@/components/client/call/call-recording-player-card";
import { CommunicationDirection, CommunicationLog, CommunicationLogStatus, CommunicationLogTab, useCommunicationLogContext } from "@/components/client/communication-log";
import CommunicationLogSelectALogPlaceholder from "@/components/client/communication-log/communication-log-select-a-log-placeholder";
import ContactProfile from "@/components/client/contact/contact-profile";
import { CallRecordingStatus, useCallCompleteMutation } from "@/components/client/graphql";
import { ApplicationDefaultProtectedPageLayout } from "@/components/client/layout";
import { useNetworkContext } from "@/components/client/network";
import { PermissionRequired } from "@/components/client/permission";
import { useTwilioContext } from "@/components/client/twilio";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { PlayWrightTestId } from "@/tests/constants";

/**
 * Panel that displays the call information and actions that can be performed on
 * the selected communication log.
 *
 * This is only shown when a communication log is selected.
 *
 * The sections are displayed in this order from top to bottom subject to
 * the visibility conditions:
 *
 * - Call Information (Always visible) - Displays information about the call.
 *
 * - Call Control Ringing (Visible when the call is ringing)
 *    - Accept Call
 *    - Reject Call
 *
 * - Call Control Live (Visible when the call is ongoing)
 *    - Mute
 *    - End Call
 *
 * - Call Complete (Visible when the call is wrapping up) - Allowas the user to mark the call as completed.
 *
 * - Call Recording Player (Visible when the call is wrapping up or completed) - Allows the user to play the recording of the call when available.
 *
 * - Call Summary (Visible when the call is ongoing, wrapping up, or completed) - Allowas the user to add a summary to the call.
 *
 * - Call Routing (Visible when the call is not ringing, ongoing, or wrapping up) - Show the routing information of the call.
 */
function ActionPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    selectedCommunicationLog,
    selectedCommunicationLogLoading
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [completeCall, {
    loading: callCompleting
  }] = useCallCompleteMutation();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`col-span-6 flex h-full flex-col overflow-hidden lg:col-span-4 ${!!selectedCommunicationLog ? "" : "hidden"}`} data-sentry-component="ActionPanel" data-sentry-source-file="page.tsx">
      <CallControlRingingCard data={selectedCommunicationLog} data-sentry-element="CallControlRingingCard" data-sentry-source-file="page.tsx" />
      <CallCompleteCard callback={{
      onCompleteCall: async () => {
        if (!selectedCommunicationLog?.id) {
          return;
        }
        await completeCall({
          variables: {
            input: {
              callId: selectedCommunicationLog.id
            }
          }
        });
      }
    }} data={{
      callId: selectedCommunicationLog?.id!,
      clientName: selectedCommunicationLog?.clientName,
      clientPhoneNumber: selectedCommunicationLog?.clientPhoneNumber ?? "",
      // Convert to milliseconds.
      duration: selectedCommunicationLog?.duration ? selectedCommunicationLog?.duration * 1000 : 0
    }} disabled={selectedCommunicationLogLoading || callCompleting} loading={callCompleting} visible={!!selectedCommunicationLog?.id && selectedCommunicationLog?.status === CommunicationLogStatus.WRAPPING_UP} data-sentry-element="CallCompleteCard" data-sentry-source-file="page.tsx" />
      <CallControlLiveCard data={selectedCommunicationLog} visible={selectedCommunicationLog?.status === CommunicationLogStatus.ONGOING} data-sentry-element="CallControlLiveCard" data-sentry-source-file="page.tsx" />
      <CallInformationCard visible={!!selectedCommunicationLog && selectedCommunicationLog?.status !== CommunicationLogStatus.RINGING && selectedCommunicationLog?.status !== CommunicationLogStatus.ONGOING && selectedCommunicationLog?.status !== CommunicationLogStatus.WRAPPING_UP} data-sentry-element="CallInformationCard" data-sentry-source-file="page.tsx" />
      <div className={`flex h-full w-full flex-col items-center gap-4 overflow-y-auto bg-[#fafbff] p-4 ${selectedCommunicationLog?.status === CommunicationLogStatus.RINGING ? "hidden" : ""}`}>
        <CallRecordingPlayerCard loading={selectedCommunicationLogLoading} processing={selectedCommunicationLog?.recording?.status === CallRecordingStatus.InProgress} source={selectedCommunicationLog?.recording?.synced ? selectedCommunicationLog?.recording?.recordingUrl || undefined : selectedCommunicationLog?.recording?.thirdPartyUrl || undefined} restricted={selectedCommunicationLog?.recording?.status === CallRecordingStatus.Restricted} visible={selectedCommunicationLog?.status === CommunicationLogStatus.WRAPPING_UP || selectedCommunicationLog?.status === CommunicationLogStatus.COMPLETED} data-sentry-element="CallRecordingPlayerCard" data-sentry-source-file="page.tsx" />
        <CallSummaryCard data={selectedCommunicationLog} initialMode={selectedCommunicationLog?.status === CommunicationLogStatus.ONGOING ? "edit" : "view"}
      /**
       * This is causing the text field to not be editable.
       * In local env, it works, but for develop env, it doesn't.
       *
       * TODO: Remove this comment when the issue is resolved.
       */
      // disabled={
      //   selectedCommunicationLog?.status !==
      //     CommunicationLogStatus.ONGOING &&
      //   selectedCommunicationLog?.status !==
      //     CommunicationLogStatus.WRAPPING_UP &&
      //   selectedCommunicationLog?.status !==
      //     CommunicationLogStatus.COMPLETED
      // }
      visible={!!selectedCommunicationLog?.id && (selectedCommunicationLog?.status === CommunicationLogStatus.ONGOING || selectedCommunicationLog?.status === CommunicationLogStatus.WRAPPING_UP || selectedCommunicationLog?.status === CommunicationLogStatus.COMPLETED)} data-sentry-element="CallSummaryCard" data-sentry-source-file="page.tsx" />
        <CallRoutingCard loading={selectedCommunicationLogLoading} routings={selectedCommunicationLog?.routings ?? []} visible={!!selectedCommunicationLog && selectedCommunicationLog?.status !== CommunicationLogStatus.RINGING && selectedCommunicationLog?.status !== CommunicationLogStatus.ONGOING && selectedCommunicationLog?.status !== CommunicationLogStatus.WRAPPING_UP} data-sentry-element="CallRoutingCard" data-sentry-source-file="page.tsx" />
      </div>
    </div>;
}

/**
 * Panel that displays the communication logs.
 *
 * Displays the active, concluded, and missed communication logs.
 * - Active communication logs are displayed on the top of the panel.
 * - Concluded and Missed communication logs are viewed in separate tabs.
 *
 * Communication Logs are selectable.
 * - Selected communication log is highlighted.
 */
function CommunicationLogPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  const urlSearchParam = useSearchParams();
  const router = useRouter();
  const tabParam = urlSearchParam.get("tab");
  const {
    user
  } = useAuthenticationContext();

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Communication Logs Active
    // =========================================================================
    communicationLogsActive,
    communicationLogsActiveLoading,
    fetchMoreCommunicationLogsActive,
    // =========================================================================
    // Communication Logs Concluded
    // =========================================================================
    communicationLogsConcluded,
    communicationLogsConcludedLoading,
    communicationLogsConcludedFetchingMore,
    fetchMoreCommunicationLogsConcluded,
    hasMoreCommunicationLogsConcluded,
    setCommunicationLogsConcludedFilter,
    // =========================================================================
    // Communication Logs Missed
    // =========================================================================
    communicationLogsMissed,
    communicationLogsMissedLoading,
    communicationLogsMissedFetchingMore,
    fetchMoreCommunicationLogsMissed,
    hasMoreCommunicationLogsMissed,
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    setSelectedCommunicationLog
  } = useCommunicationLogContext();
  const {
    connected,
    loading: networkLoading
  } = useNetworkContext();
  const {
    deviceRegistered,
    deviceRegistering
  } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [selectedTab, setSelectedTab] = useState(tabParam || "missed");

  /**
   * Communication log tabs.
   */
  const communicationLogTabs = useMemo<TabsProps["items"]>(() => {
    const _tabs: TabsProps["items"] = [{
      key: CommunicationLogTab.ALL,
      label: CommunicationLogTab.ALL
    }
    /** Uncomment for "pending" tab */
    // { key: "pending", label: "Pending" },
    ];

    /**
     * Missed call tab is visible only for users with the permission to view all call logs.
     */
    if (user?.permissions.includes(Auth0Permission.CALL_LOGS_VIEW_ALL)) {
      _tabs.push({
        key: "missed",
        label: "Missed"
      });
    }
    return _tabs;
  }, [user?.permissions]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="col-span-6 flex h-full flex-col lg:col-span-4" data-sentry-component="CommunicationLogPanel" data-sentry-source-file="page.tsx">
      {(!networkLoading && !connected || !deviceRegistering && !deviceRegistered) && <Alert className="w-full" message="Disconnected: Please reload the page." type="error" showIcon action={<Button onClick={() => {
      window.location.reload();
    }} size="small" type="primary">
              Reload
            </Button>} />}
      <CommunicationLog classNames="!h-auto" data={communicationLogsActive} data-testid={PlayWrightTestId.CommunicationLog.SECTION_ACTIVE} hideListStatusIndicator loading={communicationLogsActiveLoading} onLogClick={({
      communicationLog
    }) => {
      setSelectedCommunicationLog(communicationLog);
    }} onMaxScroll={fetchMoreCommunicationLogsActive} data-sentry-element="CommunicationLog" data-sentry-source-file="page.tsx" />
      {/* <Divider className="!m-0" /> */}
      <div className="inline-flex h-16 w-full flex-col items-start justify-center gap-2 border-b border-r border-t border-slate-200 bg-white p-4">
        <div className="inline-flex items-center justify-start gap-2 self-stretch">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
            <Input.Search allowClear className="rounded border border-slate-200 bg-violet-50" enterButton
          // loading
          placeholder="Search" onSearch={value => {
            setCommunicationLogsConcludedFilter({
              from: value,
              to: value
            });
          }} variant="filled" data-sentry-element="unknown" data-sentry-source-file="page.tsx" />
          </div>
          {/* <Button icon={<FilterOutlined />} type="text" /> */}
        </div>
      </div>
      {/* Tabs */}
      <div className="bg-white px-4">
        <Tabs defaultActiveKey="all" activeKey={selectedTab} items={communicationLogTabs} onChange={key => {
        router.push(`/?tab=${key}`);
        setSelectedTab(key);
      }} data-sentry-element="Tabs" data-sentry-source-file="page.tsx" />
      </div>

      <Divider className="-mt-1" data-sentry-element="Divider" data-sentry-source-file="page.tsx" />

      {selectedTab === CommunicationLogTab.ALL ? <CommunicationLog classNames="flex-grow !h-auto !min-h-[100px]" data={communicationLogsConcluded} data-testid={PlayWrightTestId.CommunicationLog.SECTION_CONCLUDED} hasMoreData={hasMoreCommunicationLogsConcluded} loading={communicationLogsConcludedLoading} loadingMoreData={communicationLogsConcludedFetchingMore} onLogClick={({
      communicationLog
    }) => {
      setSelectedCommunicationLog(communicationLog);
    }} onMaxScroll={fetchMoreCommunicationLogsConcluded} /> : <PermissionRequired requiredPermissions={[Auth0Permission.CALL_LOGS_VIEW_ALL]}>
          <CommunicationLog classNames="flex-grow !h-auto !min-h-[100px]" data={communicationLogsMissed} data-testid={PlayWrightTestId.CommunicationLog.SECTION_CONCLUDED} hasMoreData={hasMoreCommunicationLogsMissed} loading={communicationLogsMissedLoading} loadingMoreData={communicationLogsMissedFetchingMore} onLogClick={({
        communicationLog
      }) => {
        setSelectedCommunicationLog(communicationLog);
      }} onMaxScroll={fetchMoreCommunicationLogsMissed} />
        </PermissionRequired>}
    </div>;
}

/**
 * Panel that displays the contact information.
 *
 * This is only shown when a communication log is selected.
 *
 * Displays the following sections:
 * - Contact Profile - Displays the contact's profile information.
 * - Call Past Interactions - Displays the past interactions with the contact.
 */
function InformationPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    selectedCommunicationLog
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  const contactProfileData = useMemo(() => {
    if (!selectedCommunicationLog) {
      return;
    }
    const contact = selectedCommunicationLog.direction === CommunicationDirection.INBOUND ? selectedCommunicationLog.fromContact : selectedCommunicationLog.toContact;
    return {
      ...contact,
      id: contact?.id || selectedCommunicationLog.clientPhoneNumber,
      displayName: contact?.displayName || selectedCommunicationLog.clientPhoneNumber,
      phoneNumbers: contact?.phoneNumbers || [],
      photoUrl: contact?.photoUrl,
      email: contact?.email,
      issues: contact?.issues || []
    };
  }, [selectedCommunicationLog]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================
  return <div className={`hidden border-l lg:col-span-4 ${
  // Hide when nothing is selected.
  !!selectedCommunicationLog ? "lg:block" : "hidden"}`} data-sentry-component="InformationPanel" data-sentry-source-file="page.tsx">
      <div className="flex h-full w-full flex-col items-center gap-4 overflow-y-auto bg-[#fafbff] p-4">
        <ContactProfile visible={!!selectedCommunicationLog} data={contactProfileData} data-sentry-element="ContactProfile" data-sentry-source-file="page.tsx" />
        <CallPastInteractionsCard
      // Always visible.
      visible={!!selectedCommunicationLog} clientPhoneNumber={selectedCommunicationLog?.clientPhoneNumber} data-sentry-element="CallPastInteractionsCard" data-sentry-source-file="page.tsx" />
      </div>
    </div>;
}

/**
 * Panel that is displayed when no communication log is selected.
 *
 * Display a placeholder image and a text saying to select a communication log.
 */
function NoSelectedLogPanel() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Communication log context.
   */
  const {
    // =========================================================================
    // Selected Communication Log
    // =========================================================================
    selectedCommunicationLog
  } = useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`col-span-6 inline-flex h-full w-full flex-col items-center justify-center bg-neutral-surface-grey lg:col-span-8 ${!selectedCommunicationLog ? "" : "hidden"}`} data-sentry-component="NoSelectedLogPanel" data-sentry-source-file="page.tsx">
      <CommunicationLogSelectALogPlaceholder data-sentry-element="CommunicationLogSelectALogPlaceholder" data-sentry-source-file="page.tsx" />
    </div>;
}

/**
 * Home page.
 * This is requires authentication to be accessed.
 */
function HomePage() {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <ApplicationDefaultProtectedPageLayout data-sentry-element="ApplicationDefaultProtectedPageLayout" data-sentry-component="HomePage" data-sentry-source-file="page.tsx">
      <div className="flex h-full">
        <div className="grid h-full w-full grid-cols-12 grid-rows-1">
          <CommunicationLogPanel data-sentry-element="CommunicationLogPanel" data-sentry-source-file="page.tsx" />
          <NoSelectedLogPanel data-sentry-element="NoSelectedLogPanel" data-sentry-source-file="page.tsx" />
          <ActionPanel data-sentry-element="ActionPanel" data-sentry-source-file="page.tsx" />
          <InformationPanel data-sentry-element="InformationPanel" data-sentry-source-file="page.tsx" />
        </div>
      </div>
    </ApplicationDefaultProtectedPageLayout>;
}
export default HomePage;