/**
 * Third-party libraries
 */
import { PlayArrowIcon } from "@/components/client/svgs/icons";
import Icon from "@ant-design/icons";
import { Skeleton } from "antd";

/**
 * Project components
 */

/**
 * Audio player skeleton component.
 *
 * This displays a skeleton for the loading state of the audio player.
 */
export function AudioPlayerSkeleton() {
  return <div className="relative flex w-full items-center" data-sentry-component="AudioPlayerSkeleton" data-sentry-source-file="audio-player-skeleton.tsx">
      <div className="absolute left-4">
        <Icon component={PlayArrowIcon} className="text-lg !text-neutral-400" data-sentry-element="Icon" data-sentry-source-file="audio-player-skeleton.tsx" />
      </div>

      <Skeleton.Input size="small" active block className="min-w-0 opacity-80" style={{
      height: 34,
      width: "100%"
    }} data-sentry-element="unknown" data-sentry-source-file="audio-player-skeleton.tsx" />
    </div>;
}