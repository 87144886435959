/**
 * Third-party libraries
 */
import { LockFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

/**
 * Project components
 */
import { AudioPlayer, AudioPlayerSkeleton } from "@/components/client/audio";
import { useCommunicationLogContext } from "@/components/client/communication-log";
import { PermissionRequired } from "@/components/client/permission";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { PlayWrightTestId } from "@/tests/constants";

/**
 * Call recording player card body properties.
 */
type CallRecordingPlayerCardBodyProps = {
  /**
   * Error message to display.
   *
   * @example
   * "Unable to load audio file. Invalid."
   */
  error?: string;
  /**
   * Indicates that audio recording is being loaded. Shows a skeleton when true.
   *
   * @default
   * true
   */
  loading?: boolean;
  /**
   * Handler when an error is thrown upon loading.
   */
  onError?: (event: React.SyntheticEvent<HTMLAudioElement, Event>) => void;
  /**
   * The recording is still being processed in the backend.
   */
  processing?: boolean;
  /**
   * Indicates that the audio is restricted.
   */
  restricted?: boolean;
  /**
   * Audio source to play.
   *
   * @example
   * "https://example.com/audio.mp3"
   */
  source?: string;
};

/**
 * Call recording player card body.
 */
function CallRecordingPlayerCardBody({
  error,
  loading = true,
  onError,
  processing = false,
  restricted,
  source
}: CallRecordingPlayerCardBodyProps) {
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (loading) {
    return <AudioPlayerSkeleton />;
  }
  if (processing) {
    return <div className="text-sm text-tpl-navy">Processing...</div>;
  }
  if (restricted) {
    return <div className="flex items-center justify-center gap-2 text-tpl-navy-light">
        <LockFilled className="text-2xl" />
        <span className="text-sm" data-testid={PlayWrightTestId.AudioPlayer.AUDIO_PLAYER}>
          Restricted.
        </span>
      </div>;
  }
  if (error?.length) {
    return <div className="text-sm text-tpl-navy">{error}</div>;
  }
  if (source?.length) {
    return <AudioPlayer data-testid={PlayWrightTestId.AudioPlayer.AUDIO_PLAYER} audioSource={source} onError={onError} />;
  }
  return <div className="text-sm text-tpl-navy" data-sentry-component="CallRecordingPlayerCardBody" data-sentry-source-file="call-recording-player-card.tsx">No recording.</div>;
}

/**
 * Call Recording Player Card Props.
 */
type CallRecordingPlayerCardProps = {
  /**
   * Indicates that the call information card is visible.
   */
  visible?: boolean;
} & Pick<CallRecordingPlayerCardBodyProps, "loading" | "processing" | "restricted" | "source">;

/**
 * A card with an audio player to play the currently selected call recording.
 */
export function CallRecordingPlayerCard(props: CallRecordingPlayerCardProps) {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const {
    selectedCommunicationLog
  } = useCommunicationLogContext();
  /** If there are any errors in loading the audio. */
  const [audioLoadError, setAudioLoadError] = useState<string>();

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useEffect(() => {
    // Reset audioLoadError whenever the audioSource (currently viewed call) changes.
    setAudioLoadError(undefined);
  }, [props.source]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  // Hide if not visible or no audio source.
  if (!props.visible) {
    return null;
  }
  return <PermissionRequired requiredPermissions={[Auth0Permission.CALL_RECORDING_VIEW_ALL, Auth0Permission.CALL_RECORDING_VIEW_OWN]} data-sentry-element="PermissionRequired" data-sentry-component="CallRecordingPlayerCard" data-sentry-source-file="call-recording-player-card.tsx">
      <div className="flex animate-slide-left flex-col items-start justify-start self-stretch rounded-md border border-slate-200 bg-white">
        <div className="inline-flex items-center justify-between self-stretch border-b border-slate-200 p-4" style={{
        borderBottom: "1px solid rgba(0,0,0,0.1)"
      }}>
          <div className="text-tpl-navy">
            Recording
            {selectedCommunicationLog?.recording && !selectedCommunicationLog?.recording?.synced ? "*" : ""}
          </div>
        </div>

        <div className="flex h-[66px] flex-col items-start justify-center gap-4 self-stretch p-4">
          <CallRecordingPlayerCardBody error={audioLoadError} loading={props.loading} onError={event => {
          if (event.currentTarget.error?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
            setAudioLoadError("Unable to load audio file. Invalid.");
          }
        }} processing={props.processing} restricted={props.restricted} source={props.source} data-sentry-element="CallRecordingPlayerCardBody" data-sentry-source-file="call-recording-player-card.tsx" />
        </div>
      </div>
    </PermissionRequired>;
}